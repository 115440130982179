<template>
  <div style="overflow: -Scroll; overflow-x: hidden">
    <router-link to="/"></router-link>
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style >
* {
  padding: 0;
  margin: 0;
}
</style>